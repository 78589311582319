const WistItemList = ({items, onDeleteItem, onEditItem}) => {
    let content = (
        <div className="alert alert-info">
            There are no items in your wist yet, please go ahead and add some!
        </div>
    );
    const getDescription = (desc) => desc.split('\n').map((line, idx) =>
        <p key={idx} className="card-text">{line}</p>
    );
    if (items && items.length > 0) {
        content = (
            <div className="mt-3">
                <h3>Items</h3>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {items.map((item, idx) => (
                        <div className="col" key={idx}>
                            <div className="card h-100">
                                <div className="card-header">
                                    <span className="badge bg-primary float-end me-1">{item.state}</span>
                                    <h5 className="card-title">{item.title}</h5>
                                </div>
                                <div className="card-body">
                                    {item.description ? getDescription(item.description) : null}
                                    {item.url
                                        ? <a className="card-link link-secondary" href={item.url} target="_blank" rel="noreferrer">{item.url}</a>
                                        : null}
                                </div>
                                <div className="card-footer">
                                    <div className="btn-group">
                                        <button className="btn btn-warning btn-sm" onClick={() => onEditItem(item)}>Edit</button>
                                        <button className="btn btn-danger btn-sm" onClick={() => onDeleteItem(item.uuid)}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <div className="wist-items">
            {content}
        </div>
    );
};

export default WistItemList;
