const WistShareList = ({shares, onCopyLink, onDeleteShare, onEditShare, onViewShare, onShowQRCode}) => {
    const help_content = (
        <div>
            <p>Here are all of the shares that you've created. Send a link to a share to a friend so that they can see your Wist and mark items on it that they are going to get for you!</p>
            <p>Make sure that you only send a share to one person. You can create as many shares as you like though, maybe one for each friend that you have?</p>
        </div>
    );
    let content = (
        <div>
            {help_content}
            <div className="alert alert-info">
                You haven't shared your Wist with anybody yet 😢
            </div>
        </div>
    );
    if (shares && shares.length > 0) {
        content = (
            <div className="mt-3">
                <h3>Shares</h3>
                {help_content}
                <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-4 g-4">
                    {shares.map((share, idx) => (
                        <div className="col" key={idx}>
                            <div className="card h-100 text-dark">
                                <div className="card-header">
                                    <h5 className="card-title">{share.owner_name}&apos;s share</h5>
                                </div>
                                <div className="card-body">
                                    {share.message
                                     ?
                                     <div>
                                         <div><strong>Your message to {share.owner_name}:</strong></div>
                                         {share.message.split('\n').map((line, idx) => <div key={idx}>{line}</div>)}
                                     </div>
                                     : null}
                                </div>
                                <div className="card-footer">
                                    <div className="btn-group" role="group" aria-label="Wist share links">
                                        <button className="btn btn-sm btn-primary" onClick={() => onCopyLink(share)}>Copy link</button>
                                        <button className="btn btn-sm btn-primary" onClick={() => onShowQRCode(share)}>Show QR code</button>
                                    </div>
                                    <div className="btn-group" role="group" aria-label="Wist share actions">
                                        <button id="share-actions" className="btn btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Actions</button>
                                        <ul className="dropdown-menu" aria-labelledby="share-actions">
                                            <li><button className="dropdown-item" onClick={() => onViewShare(share)}>View</button></li>
                                            <li><button className="dropdown-item" onClick={() => onEditShare(share)}>Edit</button></li>
                                            <li><button className="dropdown-item" onClick={() => onDeleteShare(share.uuid)}>Delete</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <div className="wist-shares">
            {content}
        </div>
    );
};

export default WistShareList;
