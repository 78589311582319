import { useState } from "react";

const EditWistItem = ({item, onSubmit}) => {
    const [error, setError] = useState(null);
    const [description, setDescription] = useState(item ? item.description : '');
    const [state, setState] = useState(item ? item.state : 'wanted');
    const [title, setTitle] = useState(item ? item.title : '');
    const [url, setUrl] = useState(item ? item.url : '');

    const buildWistItem = () => ({
        uuid: item.uuid,
        wist_uuid: item.wist_uuid,
        description,
        state,
        title,
        url,
    });

    const handleSubmit = () => {
        if (!title || title.trim().length === 0) {
            setError('Item title must not be empty');
            return
        }
        setError(null);
        onSubmit(buildWistItem());
        setDescription('');
        setState('wanted');
        setTitle('');
        setUrl('');
    };
    
    return (
        <form className="row g-3">
            {error ? <div className="alert alert-warning">{error}</div> : null}
            <div className="col-xs-12 col-sm-6">
                <label htmlFor="item_title" className="form-label">Item title</label>
                <input
                    className="form-control"
                    id="item_title"
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    value={title}
                />
            </div>
            <div className="col-xs-12 col-sm-6">
                <label htmlFor="item_state" className="form-label">Current state</label>
                <select
                    className="form-control"
                    id="item_state"
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                >
                    <option value="wanted">Wanted</option>
                    <option value="owned">Owned</option>
                </select>
            </div>
            <div className="col-12">
                <label htmlFor="item_url" className="form-label">Item URL (optional)</label>
                <input
                    className="form-control"
                    id="item_url"
                    onChange={(e) => setUrl(e.target.value)}
                    type="text"
                    value={url}
                />
            </div>
            <div className="col-12">
                <label htmlFor="item_description" className="form-label">Item description (optional)</label>
                <textarea
                    className="form-control"
                    id="item_description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
            </div>
            <div className="col-12">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSubmit}
                >{ item.uuid ? 'Update item' : 'Add item!' }</button>
            </div>
        </form>
    )
};

export default EditWistItem;
