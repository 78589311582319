import { useState } from "react";

const NewWist = ({onSubmit}) => {
    const [error, setError] = useState(null);
    const [description, setDescription] = useState('');
    const [ownerName, setOwnerName] = useState('');
    const [published, setPublished] = useState(true);
    const [title, setTitle] = useState('');

    const buildWist = () => ({
        description,
        owner_name: ownerName,
        published,
        title,
    });

    const handleSubmit = () => {
        if (!ownerName || ownerName.trim().length === 0) {
            setError('Your name must not be empty');
            return
        }
        if (!title || title.trim().length === 0) {
            setError('Wist title must not be empty');
            return
        }
        setError(null);
        onSubmit(buildWist());
        setDescription('');
        setOwnerName('');
        setPublished(false);
        setTitle('');
    };

    return (
        <form className="row g-3">
            {error ? <div className="alert alert-warning">{error}</div> : null}
            <div className="col-xs-12 col-sm-6">
                <label className="form-label" htmlFor="ownerName">Your name</label>
                <input
                    className="form-control"
                    id="ownerName"
                    onChange={(e) => setOwnerName(e.target.value)}
                    type="text"
                    value={ownerName}
                />
            </div>
            <div className="col-xs-12 col-sm-6">
                <label className="form-label" htmlFor="title">Wist title</label>
                <input
                    className="form-control"
                    id="title"
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    value={title}
                />
            </div>
            <div className="col-12">
                <label className="form-label" htmlFor="description">Wist description (optional)</label>
                <textarea
                    className="form-control"
                    id="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
            </div>
            <div className="col-12">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        id="wistPublished"
                        onChange={(e) => setPublished(e.target.checked)}
                        type="checkbox"
                        checked={published}
                    />
                    <label className="form-check-label" htmlFor="wistPublished">Allow people to whom you have shared your Wist to see its items?</label>
                </div>
            </div>
            <div className="col-12">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSubmit}
                >Create new Wist!</button>
            </div>
        </form>
    )
};

export default NewWist;
