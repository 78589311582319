import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
    action,
    makeObservable,
    observable,
    runInAction,
    toJS,
} from "mobx";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import QRCode from "react-qr-code";

import API from "../services/api";
import EditWist from "../component/edit_wist";
import EditWistItem from "../component/edit_wist_item";
import EditWistShare from "../component/edit_wist_share";
import NewWistItem from "../component/new_wist_item";
import NewWistShare from "../component/new_wist_share";
import WistDetails from "../component/wist_details";
import WistItemList from "../component/wist_item_list";

class ViewWistState {
    error = null;
    wist = null;
    items = [];
    shares = [];

    constructor() {
        makeObservable(this, {
            addNewItem: action,
            addNewShare: action,
            deleteItem: action,
            deleteShare: action,
            error: observable,
            items: observable,
            loadWist: action,
            loadWistItems: action,
            loadWistShares: action,
            updateItem: action,
            updateShare: action,
            shares: observable,
            wist: observable,
        });
    }

    setPageTitle(wist) {
        if (wist) {
            document.title = `My lishwist: ${wist.title}`;
        } else {
            document.title = 'My lishwist';
        }
    }

    async loadWist(uuid) {
        const res = await API.api_get(`/wists/${encodeURIComponent(uuid)}`);
        runInAction(() => {
            if (res.success) {
                this.error = null;
                this.wist = res.payload.data;
            } else {
                this.error = res.error.toString();
                this.wist = null;
            }
        });
    }

    async loadWistItems(uuid) {
        const res = await API.api_get(`/wists/${encodeURIComponent(uuid)}/items`);
        runInAction(() => {
            if (res.success) {
                this.error = null;
                this.items = res.payload.data;
            } else {
                this.error = res.error.toString();
                this.items = [];
            }
        });
    }

    async loadWistShares(uuid) {
        const res = await API.api_get(`/wists/${encodeURIComponent(uuid)}/shares`);
        runInAction(() => {
            if (res.success) {
                this.error = null;
                this.shares = res.payload.data;
            } else {
                this.error = res.error.toString();
                this.shares = [];
            }
        });
    }

    async addNewItem(item) {
        if (!this.wist) {
            this.error = "No Wist currently loaded, unable to add item";
            return;
        }
        const res = await API.api_post(`/wists/${encodeURIComponent(this.wist.uuid)}/items`, item);
        runInAction(() => {
            if (res.success) {
                this.items.push(res.payload.data);
            } else {
                this.error = res.error.toString();
            }
        });
    }

    async addNewShare(wist_share) {
        const res = await API.api_post(`/wists/${encodeURIComponent(wist_share.wist_uuid)}/shares`, wist_share);
        runInAction(() => {
            if (res.success) {
                this.shares.push(res.payload.data);
            } else {
                this.error = res.error.toString();
            }
        });
    }

    async deleteItem(uuid) {
        if (!this.wist) {
            this.error = "No Wist currently loaded, unable to delete item";
            return;
        }
        if (window.confirm('Are you sure you want to delete this item from wour Wist?')) {
            const res = await API.api_delete(`/wists/${encodeURIComponent(this.wist.uuid)}/items/${encodeURIComponent(uuid)}`);
            runInAction(() => {
                if (res.success) {
                    this.items = this.items.filter((item) => item.uuid !== uuid);
                } else {
                    this.error = res.error.toString();
                }
            });
        }
    }

    async deleteShare(uuid) {
        if (!this.wist) {
            this.error = "No Wist currently loaded, unable to delete share";
            return;
        }
        if (window.confirm('Are you sure you want to delete this share? Once deleted, the person to whom the share link was given will no longer be able to use it.')) {
            const res = await API.api_delete(`/wists/${encodeURIComponent(this.wist.uuid)}/shares/${encodeURIComponent(uuid)}`);
            runInAction(() => {
                if (res.success) {
                    this.shares = this.shares.filter((share) => share.uuid !== uuid);
                } else {
                    this.error = res.error.toString();
                }
            });
        }
    }

    async updateWist(model) {
        if (!this.wist) {
            this.error = "No Wist currently loaded, unable to update";
            return;
        }
        const res = await API.api_put(
            `/wists/${encodeURIComponent(this.wist.uuid)}`,
            model
        );
        runInAction(() => {
            if (res.success) {
                this.wist = res.payload.data;
                this.setPageTitle(this.wist);
            } else {
                this.error = res.error.toString();
            }
        });
    }

    async updateItem(item) {
        if (!this.wist) {
            this.error = "No Wist currently loaded, unable to update item";
            return;
        }
        const res = await API.api_put(
            `/wists/${encodeURIComponent(this.wist.uuid)}/items/${encodeURIComponent(item.uuid)}`,
            item
        );
        runInAction(() => {
            if (res.success) {
                this.items = this.items.map((item2) => item2.uuid === item.uuid ? res.payload.data : item2);
            } else {
                this.error = res.error.toString();
            }
        });
    }

    async updateShare(share) {
        if (!this.wist) {
            this.error = "No Wist currently loaded, unable to update share";
            return;
        }
        const res = await API.api_put(
            `/wists/${encodeURIComponent(this.wist.uuid)}/shares/${encodeURIComponent(share.uuid)}`,
            share
        );
        runInAction(() => {
            if (res.success) {
                this.shares = this.shares.map((share2) => share2.uuid === share.uuid ? res.payload.data : share2);
            } else {
                this.error = res.error.toString();
            }
        });
    }
}

const ViewWistPage = observer(() => {
    const [state] = useState(new ViewWistState());
    const history = useHistory();
    const { error, items, shares, wist } = state;
    const { uuid } = useParams();
    const [editItemIsOpen, setEditItemOpened] = useState(false);
    const [editWistIsOpen, setEditWistOpened] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [shareWistIsOpen, setShareWistOpened] = useState(false);
    const [editWistShareIsOpen, setEditWistShareOpened] = useState(false);
    const [showQRCodeOpened, setShowQRCodeOpened] = useState(false);
    const [shareForQRCode, setShareForQRCode] = useState(null);
    const [editShare, setEditShare] = useState(null);
    const [showToast, setShowToast] = useState(null);
    const [toastMessage, setToastMessage] = useState(null);

    useEffect(() => {
        async function fetchData(uuid) {
            await state.loadWist(uuid);
            await state.loadWistItems(uuid);
            await state.loadWistShares(uuid);
            state.setPageTitle(state.wist);
        }
        state.setPageTitle(null);
        fetchData(uuid);
    }, [state, uuid]);

    if (error) {
        return (
            <div className="alert alert-danger">{error}</div>
        );
    }
    if (!wist) {
        return (
            <div className="alert alert-info">Loading wist, please wait...</div>
        );
    }

    const closeEditModal = () => {
        setEditWistOpened(false);
    };

    const closeEditItemModal = () => {
        setEditItemOpened(false);
    };

    const closeShareModal = () => {
        setShareWistOpened(false);
    };

    const closeEditShareModal = () => {
        setEditWistShareOpened(false);
    };

    const closeShowQRCodeModal = () => {
        setShowQRCodeOpened(false);
    };

    const showToastMessage = (msg) => {
        setToastMessage(msg);
        setShowToast(true);
    };

    const getShareLink = (share) => {
        const l = window.location;
        return l.protocol + '//' + l.host + `/view-wist-share/${share.uuid}`;
    };

    const copyShareLink = (share) => {
        navigator.clipboard.writeText(getShareLink(share));
        showToastMessage(`📋 Hey there, I just copied the link to ${share.owner_name}'s share to your clipboard! 🖇`);
    };

    return (
        <div className="wist">
            <Modal
                show={editWistIsOpen}
                onHide={closeEditModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit wist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditWist
                        wist={wist}
                        onSubmit={(wist) => {
                            closeEditModal();
                            state.updateWist(wist);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={editItemIsOpen}
                onHide={closeEditItemModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditWistItem
                        item={editItem}
                        onSubmit={(item) => {
                            closeEditItemModal();
                            state.updateItem(item);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={shareWistIsOpen}
                onHide={closeShareModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share your wist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewWistShare
                        wistUuid={wist.uuid}
                        onSubmit={(share) => {
                            closeShareModal();
                            state.addNewShare(share);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={editWistShareIsOpen}
                onHide={closeEditShareModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit share</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditWistShare
                        share={editShare}
                        onSubmit={(share) => {
                            closeEditShareModal();
                            state.updateShare(share);
                        }}
                    />
                </Modal.Body>
            </Modal>
            <Modal
                show={showQRCodeOpened}
                onHide={closeShowQRCodeModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {shareForQRCode ? <div>Link to {shareForQRCode.owner_name}&apos;s share</div> : <div>Share QR code</div>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        {shareForQRCode ? <QRCode value={getShareLink(shareForQRCode)} /> : null}
                    </div>
                </Modal.Body>
            </Modal>
            <WistDetails
                wist={toJS(wist)}
                shares={toJS(shares)}
                onEdit={(_wist) => setEditWistOpened(true)}
                onShare={(_wist) => setShareWistOpened(true)}
                onCopyShareLink={(share) => copyShareLink(share)}
                onDeleteShare={(uuid) => state.deleteShare(uuid)}
                onEditShare={(share) => {
                    setEditShare(share);
                    setEditWistShareOpened(true);
                }}
                onShowQRCode={(share) => {
                    setShareForQRCode(share);
                    setShowQRCodeOpened(true);
                }}
                onViewShare={(share) => {
                    let message = 'By viewing this page you\'ll be able to see who has claimed any items, which will just ruin the surprise!\n\n';
                    message += 'If you just want to send the link, try clicking "Copy link" instead.\n\n';
                    message += 'Are you sure you want to view this share?';
                    if (window.confirm(message)) {
                        history.push(`/view-wist-share/${encodeURIComponent(share.uuid)}`);
                    }
                }}
            />
            <WistItemList
                items={toJS(items)}
                onDeleteItem={(uuid) => state.deleteItem(uuid)}
                onEditItem={(item) => {
                    setEditItem(item);
                    setEditItemOpened(true);
                }}
            />
            <hr />
            <NewWistItem onSubmit={(item) => state.addNewItem(item)} />
            <ToastContainer position="top-end" className="p-3 position-fixed">
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={7500} autohide>
                    <Toast.Header>
                        <strong className="me-auto">Lishwist alerts</strong>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
});

export default ViewWistPage;
