const WistItemClaimList = ({share, item}) => {
    return (
        <div className="claims">
            {item.claims.map((claim, idx) => {
                if (claim.wist_share_uuid === share.uuid) {
                    return (<div key={idx} className="alert alert-success">You have claimed this item!</div>);
                } else {
                    return (<div key={idx} className="alert alert-warning">This item has been claimed by {claim.owner_name}</div>);
                }
            })}
        </div>
    );
};

export default WistItemClaimList;
