import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import HomePage from "./page/home";
import ViewWistPage from "./page/view-wist";
import ViewWistSharePage from "./page/view-wist-share";
import "./App.css";

function App() {
    return (
        <Router>
            <div>
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img
                                className="img-fluid"
                                src="/Lishwist-logo.svg"
                                alt="Lishwist"
                                style={{height: "55px"}}
                            />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbar"
                            aria-controls="navbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbar">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <div className="container mt-3">
                    <Switch>
                        <Route path="/view-wist/:uuid">
                            <ViewWistPage />
                        </Route>
                        <Route path="/view-wist-share/:uuid">
                            <ViewWistSharePage />
                        </Route>
                        <Route path="/">
                            <HomePage />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
}

export default App;
