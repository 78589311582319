import { toJS } from "mobx";

import WistShareList from "./wist_share_list";

const WistDetails = ({wist, shares, onEdit, onShare, onCopyShareLink, onDeleteShare, onEditShare, onShowQRCode, onViewShare}) => (
    <div className="wist-details card">
        <div className="card-body bg-secondary text-light rounded">
            <div className="row">
                <div className="col">
                    <h1 className="wist-title">Your wist</h1>
                </div>
                <div className="col text-end">
                    <div className="btn-group">
                        <button className="btn btn-warning" onClick={() => onEdit(wist)}>Edit</button>
                        <button className="btn btn-info" onClick={() => onShare(wist)}>Share...</button>
                    </div>
                </div>
            </div>
            <p className="card-text">This is your new Wist (a list of items). Please bookmark this page as it's your only link to view and edit this list!</p>
            <p className="card-text">
                <strong>Important: </strong>
                Please don't share this link with anyone else. Anyone with this link will be able to view and modify your list.
            </p>
            <ul className="list-group text-dark">
                <li className="list-group-item">
                    <strong>Name: </strong>{wist.owner_name}
                </li>
                <li className="list-group-item">
                    <strong>Title: </strong>{wist.title}
                </li>
                <li className="list-group-item">
                    <strong>Description: </strong>{wist.description}
                </li>
                <li className="list-group-item">
                    <strong>Items visible on shares? </strong>{wist.published ? "Yes" : "No"}
                    {wist.published ? null : <div className="alert alert-warning">IMPORTANT: the people to whom you have shared your Wist are currently unable to see the items on it because of this setting!</div>}
                </li>
            </ul>
            <hr />
            <WistShareList
                shares={toJS(shares)}
                onCopyLink={onCopyShareLink}
                onDeleteShare={onDeleteShare}
                onEditShare={onEditShare}
                onShowQRCode={onShowQRCode}
                onViewShare={onViewShare}
            />
        </div>
    </div>
);

export default WistDetails;
