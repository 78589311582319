import React, { useEffect, useState } from "react";
import {
    action,
    makeObservable,
    observable,
    runInAction,
    toJS,
} from "mobx";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import API from "../services/api";

import WistShareDetails from '../component/wist_share_details';
import WistShareItemList from '../component/wist_share_item_list';

class ViewWistShareState {
    error = null;
    share = null;
    items = [];

    constructor() {
        makeObservable(this, {
            claimItem: action,
            loadShare: action,
            loadShareItems: action,
            unclaimItem: action,
            error: observable,
            items: observable,
            share: observable,
        });
    }

    async claimItem(share, item) {
        let message = `Are you sure you want to claim this item? Claiming signals that you intend to get this item for ${share.wist_owner_name} and your claim will be shown to other people with whom ${share.wist_owner_name} has shared.`;
        if (item.claims.length > 0) {
            message += '\n\nWARNING: This item already has a claim! Please double-check before buying anything!';
        }
        if (window.confirm(message)) {
            const res = await API.api_post(`/user-wist-shares/${encodeURIComponent(share.uuid)}/items/${encodeURIComponent(item.uuid)}/claim`);
            runInAction(() => {
                if (res.success) {
                    this.loadShareItems(share.uuid);
                } else {
                    this.error = res.error.toString();
                    this.share = null;
                }
            });
        }
    }

    async loadShare(uuid) {
        const res = await API.api_get(`/user-wist-shares/${encodeURIComponent(uuid)}`);
        runInAction(() => {
            if (res.success) {
                this.error = null;
                this.share = res.payload.data;
            } else {
                this.error = res.error.toString();
                this.share = null;
            }
        });
    }

    async loadShareItems(uuid) {
        const res = await API.api_get(`/user-wist-shares/${encodeURIComponent(uuid)}/items`);
        runInAction(() => {
            if (res.success) {
                this.error = null;
                this.items = res.payload.data;
            } else {
                this.error = res.error.toString();
                this.items = [];
            }
        });
    }

    async unclaimItem(share, item) {
        if (window.confirm(`Are you sure you want to unclaim this item?`)) {
            const res = await API.api_delete(`/user-wist-shares/${encodeURIComponent(share.uuid)}/items/${encodeURIComponent(item.uuid)}/claim`);
            runInAction(() => {
                if (res.success) {
                    this.loadShareItems(share.uuid);
                } else {
                    this.error = res.error.toString();
                    this.share = null;
                }
            });
        }
    }

}

const ViewWistSharePage = observer(() => {
    const [state] = useState(new ViewWistShareState());
    const { error, items, share } = state;
    const { uuid } = useParams();

    useEffect(() => {
        async function fetchData(uuid) {
            await state.loadShare(uuid);
            await state.loadShareItems(uuid);
            document.title = `${state.share.wist_owner_name}'s lishwist`;
        }
        document.title = 'Lishwist: my share';
        fetchData(uuid);
    }, [state, uuid]);

    if (!share) {
        return (
            <div className="alert alert-info">Loading wist share, please wait...</div>
        );
    }

    return (
        <div className="wist-share">
            {share ? <WistShareDetails share={toJS(share)} /> : null }
            {error ? <div className="alert alert-danger">{error}</div> : null}
            {!error && share && items
             ? <WistShareItemList
                share={toJS(share)}
                items={toJS(items)}
                onClaimItem={(item) => state.claimItem(share, item)}
                onUnclaimItem={(item) => state.unclaimItem(share, item)}
               /> : null}
        </div>
    );
});

export default ViewWistSharePage;
