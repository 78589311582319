import EditWistItem from "./edit_wist_item";

const NewWistItem = ({onSubmit}) => {
    const item = {
        description: '',
        state: 'wanted',
        title: '',
        url: '',
    };
    return <EditWistItem item={item} onSubmit={onSubmit} />
};

export default NewWistItem;
