class API {
    static BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3100/api/v1";

    static async api_request(endpoint, method, payload=undefined) {
        try {
            const res_raw = await fetch(
                `${this.BASE_URL}${endpoint}`,
                {
                    body: payload ? JSON.stringify(payload) : undefined,
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method,
                });
            const res = await res_raw.json();
            if (!res_raw.ok) {
                return {
                    error: res.message,
                    payload: null,
                    success: false,
                };
            }
            return ({
                error: null,
                payload: res,
                success: true,
            });
        } catch (err) {
            return ({
                error: err,
                success: false,
            });
        }
    }

    static async api_delete(endpoint) {
        return await this.api_request(endpoint, 'DELETE');
    }

    static async api_get(endpoint) {
        return await this.api_request(endpoint, 'GET');
    }

    static async api_patch(endpoint, payload) {
        return await this.api_request(endpoint, 'PATCH', payload);
    }

    static async api_post(endpoint, payload) {
        return await this.api_request(endpoint, 'POST', payload);
    }

    static async api_put(endpoint, payload) {
        return await this.api_request(endpoint, 'PUT', payload);
    }
}

export default API;
