const WistShareDetails = ({share}) => (
    <div className="wist-share-details card">
        <div className="card-body bg-secondary text-light rounded">
            <div className="row">
                <div className="col">
                    <h1 className="wist-title">{share.owner_name}'s wist share</h1>
                </div>
            </div>
            <p className="card-text">{share.wist_owner_name} has shared their wishlist (called a Wist) with you!</p>
                {share.message
                 ?
                 <div className="alert alert-info">
                     <strong>Message from {share.wist_owner_name}:</strong>
                     {share.message.split('\n').map((line, idx) => <div key={idx}>{line}</div>)}
                 </div>
                 : null}
            <p className="card-text">This page is your own personal view of their Wist. Here you can claim an item (you're planning on getting it for them) or unclaim an item (you changed your mind about that...)</p>
            <p className="card-text">
                <strong>Important: </strong>
                Please don't share this link with anyone else. If the owner of the Wist wants to share it with others then they need to create different share links for each person.
            </p>
            <ul className="list-group text-dark">
                <li className="list-group-item">
                    <strong>Wist's owner: </strong>{share.wist_owner_name}
                </li>
                <li className="list-group-item">
                    <strong>Their Wist's title: </strong>{share.wist_title}
                </li>
                <li className="list-group-item">
                    <strong>Their Wist's description: </strong>{share.wist_description}
                </li>
            </ul>
        </div>
    </div>
);

export default WistShareDetails;
