import EditWistShare from "./edit_wist_share";

const NewWistShare = ({wistUuid, onSubmit}) => {
    const share = {
        owner_name: '',
        wist_uuid: wistUuid,
    };
    return <EditWistShare share={share} onSubmit={onSubmit} />
};

export default NewWistShare;
