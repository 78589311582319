import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import API from "../services/api";
import NewWist from "../component/new_wist";

const HomePage = () => {
    const history = useHistory();
    const [error, setError] = useState(null);

    useEffect(() => {
        document.title = 'Lishwist';
    });

    const createNewWist = async (wist) => {
        const res = await API.api_post("/wists", wist);
        if (res.success) {
            setError(null);
            history.push(`/view-wist/${encodeURIComponent(res.payload.data.uuid)}`);
        } else {
            setError(res.error.toString());
        }
    };

    return (
        <div>
            <div className="card mb-3">
                <div className="card-body">
                    <h1 className="card-title">Welcome to Lishwist!</h1>
                    <p className="card-text"></p>
                    <p className="card-text">
                        Lishwist allows you to create your own personal wish-list (known as a
                        Wist) where you can keep track of items you want.
                    </p>
                    <p className="card-text">
                        You can manage the items on this list: edit the details, add or remove
                        items, mark items as already owned, etc.
                    </p>
                    <p className="card-text">
                        But what's the use of just wishing you had the things on the list?
                        That's where sharing comes in!
                    </p>
                    <p className="card-text">
                        You can share your Wists with your friends by creating a per-friend
                        sharing link. With this link, your friends can then "claim" items, which
                        means that they intend to get those items for you!
                    </p>
                    <p className="card-text">
                        Your friends can see if another friend intends to get an item, meaning
                        that you shouldn't get things twice. But you can't see what your friends
                        plan to get for you, so you still get a nice surprise!
                    </p>
                    <p className="card-text">
                        To get started, create a new Wist by filling out the form below.
                    </p>
                </div>
            </div>
            <h3>Create a new Wist</h3>
            {error ? <div className="alert alert-danger">Unable to create a new Wist: {error}</div>: null}
            <NewWist onSubmit={createNewWist} />
        </div>
    );
};

export default HomePage;
