import WistItemClaimList from './wist_item_claim_list';

const WistShareItemList = ({share, items, onClaimItem, onUnclaimItem}) => {
    let content = (
        <div className="alert alert-info">
            There are no items in this Wist, which either means the owner doesn't want anything or they haven't added anything yet!
        </div>
    );

    const haveClaimedItem = (item) => item.claims.some((claim) => claim.wist_share_uuid === share.uuid);

    const getDescription = (desc) => desc.split('\n').map((line, idx) =>
        <p key={idx} className="card-text">{line}</p>
    );

    if (items && items.length > 0) {
        content = (
            <div className="mt-3">
                <h3>Items</h3>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                    {items.map((item, idx) => (
                        <div className="col" key={idx}>
                            <div className={`card h-100 text-white ${item.state === "wanted" ? "bg-primary" : "bg-secondary"}`}>
                                <div className="card-header">
                                    <span className={`badge float-end me-1 ${item.state === "wanted" ? "bg-success" : "bg-warning"}`}>{item.state}</span>
                                    <h5 className="card-title">{item.title}</h5>
                                </div>
                                <div className="card-body">
                                    {item.state === "owned"
                                     ? <div className="alert alert-info">{share.wist_owner_name} has marked this item as "owned", which means that they already have it!</div>
                                     : null}
                                    {item.description ? getDescription(item.description) : null}
                                    {item.url
                                        ? <a className="card-link link-secondary text-white" href={item.url} target="_blank" rel="noreferrer">{item.url}</a>
                                        : null}
                                    <WistItemClaimList share={share} item={item} />
                                </div>
                                <div className="card-footer">
                                    {item.state === "wanted"
                                     ?
                                    <div className="btn-group">
                                        {haveClaimedItem(item)
                                         ? null
                                         : <button className="btn btn-warning btn-sm" onClick={() => onClaimItem(item)}>Claim</button>}
                                        {haveClaimedItem(item)
                                         ? <button className="btn btn-danger btn-sm" onClick={() => onUnclaimItem(item)}>Unclaim</button>
                                         : null}
                                    </div>
                                     : null}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <div className="wist-share-items">
            {content}
        </div>
    );
};

export default WistShareItemList;
