import { useState } from "react";

const EditWist = ({wist, onSubmit}) => {
    const [error, setError] = useState(null);
    const [description, setDescription] = useState(wist ? wist.description : '');
    const [owner_name, setOwnerName] = useState(wist ? wist.owner_name : '');
    const [published, setPublished] = useState(wist ? wist.published : false);
    const [title, setTitle] = useState(wist ? wist.title : '');

    const buildWist = () => ({
        uuid: wist.uuid,
        description,
        owner_name,
        published,
        title,
    });

    const handleSubmit = () => {
        if (!owner_name || owner_name.trim().length === 0) {
            setError('Your name must not be empty');
            return
        }
        if (!title || title.trim().length === 0) {
            setError('Wist title must not be empty');
            return
        }
        setError(null);
        onSubmit(buildWist());
        setDescription('');
        setOwnerName('');
        setPublished(false);
        setTitle('');
    };

    return (
        <form className="row g-3">
            {error ? <div className="alert alert-warning">{error}</div> : null}
            <div className="col-xs-12 col-sm-6">
                <label htmlFor="owner_name" className="form-label">Your name</label>
                <input
                    className="form-control"
                    id="owner_name"
                    onChange={(e) => setOwnerName(e.target.value)}
                    type="text"
                    value={owner_name}
                />
            </div>
            <div className="col-xs-12 col-sm-6">
                <label htmlFor="title" className="form-label">Wist title</label>
                <input
                    className="form-control"
                    id="title"
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    value={title}
                />
            </div>
            <div className="col-12">
                <div className="form-check">
                    <input
                        className="form-check-input"
                        id="wistPublished"
                        onChange={(e) => setPublished(e.target.checked)}
                        type="checkbox"
                        checked={published}
                    />
                    <label className="form-check-label" htmlFor="wistPublished">Show items to people to whom you have shared this Wist?</label>
                </div>
            </div>
            <div className="col-12">
                <label htmlFor="description" className="form-label">Description (optional)</label>
                <textarea
                    className="form-control"
                    id="description"
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                />
            </div>
            <div className="col-12">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSubmit}
                >{ 'Update' }</button>
            </div>
        </form>
    )
};

export default EditWist;
