import { useState } from "react";

const EditWistShare = ({share, onSubmit}) => {
    const [error, setError] = useState(null);
    const [ownerName, setOwnerName] = useState(share ? share.owner_name : '');
    const [message, setMessage] = useState(share && share.message ? share.message : '');

    const buildWistShare = () => ({
        owner_name: ownerName,
        message: message && message.trim().length > 0 ? message : null,
        uuid: share.uuid,
        wist_uuid: share.wist_uuid,
    });

    const handleSubmit = () => {
        if (!ownerName || ownerName.trim().length === 0) {
            setError('Recipient\'s name must not be empty');
            return
        }
        setError(null);
        onSubmit(buildWistShare());
        setOwnerName('');
        setMessage('');
    };

    return (
        <form className="row g-3">
            {error ? <div className="alert alert-warning">{error}</div> : null}
            <div className="col-xs-12">
                <label htmlFor="ownerName" className="form-label">Recipient&apos;s name</label>
                <input
                    className="form-control"
                    id="ownerName"
                    onChange={(e) => setOwnerName(e.target.value)}
                    type="text"
                    value={ownerName}
                />
            </div>
            <div className="col-xs-12">
                <label htmlFor="message" className="form-label">Optional message for the recipient</label>
                <textarea
                    className="form-control"
                    id="message"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                />
            </div>
            <div className="col-12">
                <button
                    className="btn btn-primary"
                    type="button"
                    onClick={handleSubmit}
                >{ share && share.uuid ? 'Update share' : 'Add share!' }</button>
            </div>
        </form>
    )
};

export default EditWistShare;
